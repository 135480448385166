import { useNavigate } from "react-router-dom";
import schoolIcon from "../../assets/images/School.svg";
import classIcon from "../../assets/images/Classess.svg";
import subjectIcon from "../../assets/images/Subject.svg";
import gameIcon from "../../assets/images/Game.svg";
import userIcon from "../../assets/images/Users.svg";
import reportIcon from "../../assets/images/reportIcon.svg";
import productIcon from "../../assets/images/Product.svg";
import olympiadIcon from "../../assets/images/olympiad.svg";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const VerticalMenu = () => {
  let { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <>
      <div id="vertical_menu" className="verticle-menu">
        <div className="menu-list">
          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="Flagging">{t("Schools.main_title")}</Tooltip>
              }
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/schools")}
              >
                <img src={schoolIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>
          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="Flagging">{t("Users.main_title")}</Tooltip>}
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/users")}
              >
                <img src={userIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>
          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="Flagging">{t("Schools.classes")}</Tooltip>}
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/classes")}
              >
                <img src={classIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>
          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="Flagging">{t("Schools.subjects")}</Tooltip>}
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/subjects")}
              >
                <img src={subjectIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>
          {/* <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="Flagging">{t("Topics.main_title")}</Tooltip>
              }
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/topics")}
              >
                <img src={reportIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown> */}
          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="Flagging">{t("Common.games")}</Tooltip>}
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <img src={gameIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/game/quiz")}>
                {t("Quiz.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/interactive")}>
                {t("Interactive.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/slide")}>
                {t("Slide.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/jigsaw")}>
                {t("Jigsaw.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/vocabulary")}>
                {t("Vocabulary.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/flow-charts")}>
                {t("Flow_Charts.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/comprehensive")}>
                {t("Comprehensive.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/photo-games")}>
                {t("Photo_Games.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/brain-teaser")}>
                {t("Brain_Teaser.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/game/memory")}>
                {t("Memory_Games.main_title")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="Flagging">{t("Common.Olympiad")}</Tooltip>}
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <img src={olympiadIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/olympiad/quiz")}>
                {t("Quiz.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/olympiad/interactive")}>
                {t("Interactive.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/olympiad/slide")}>
                {t("Slide.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/olympiad/jigsaw")}>
                {t("Jigsaw.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/olympiad/vocabulary")}>
                {t("Vocabulary.main_title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/olympiad/flow-charts")}>
                {t("Flow_Charts.main_title")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate("/olympiad/comprehensive")}
              >
                {t("Comprehensive.main_title")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="Flagging">{t("Standards.main_title")}</Tooltip>
              }
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/standards")}
              >
                <img src={productIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>

          <Dropdown drop="end">
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={
                <Tooltip id="Flagging">{t("Subscription.main_title")}</Tooltip>
              }
            >
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                onClick={() => navigate("/subscriptions")}
              >
                <img src={reportIcon} alt="icon" className="menu-icon" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default VerticalMenu;
