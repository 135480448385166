import { Routes, Route, RoutesProps } from "react-router-dom";
import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import DefaultPage from "../components/Common/DefaultPage";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));
  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const Otp = React.lazy(() => import("../components/Login/Otp"));
  const ResetPassword = React.lazy(
    () => import("../components/Login/ResetPassword")
  );
  const ChangePassword = React.lazy(
    () => import("../components/Login/ChangePassword")
  );

  const SchoolsList = React.lazy(
    () => import("../components/Schools/SchoolsList")
  );
  // const AddSchool = React.lazy(() => import("../components/Schools/AddSchool"));
  const AddSchool = React.lazy(
    () => import("../components/Schools/AddSchoolWithUserInfo")
  );
  const EditSchool = React.lazy(
    () => import("../components/Schools/Edit/TabView")
  );
  const SchoolImport = React.lazy(() => import("../components/Schools/Import"));

  const TeachersList = React.lazy(
    () => import("../components/Teachers/ListView")
  );
  const AddTeacher = React.lazy(() => import("../components/Teachers/AddView"));
  const EditTeacher = React.lazy(
    () => import("../components/Teachers/Edit/TabView")
  );

  const TeacherClassesList = React.lazy(
    () => import("../components/Teachers/ClassSubject/ListView")
  );

  const ClassesList = React.lazy(() => import("../components/Class/Classes"));
  const AddClass = React.lazy(() => import("../components/Class/AddClass"));
  const EditClass = React.lazy(() => import("../components/Class/EditClass"));

  const StandardList = React.lazy(() => import("../components/Standard/List"));
  const AddStandard = React.lazy(() => import("../components/Standard/Add"));
  const EditStandard = React.lazy(() => import("../components/Standard/Edit"));

  const SubjectList = React.lazy(() => import("../components/Subject/List"));
  const AddSubject = React.lazy(() => import("../components/Subject/AddView"));
  const EditSubject = React.lazy(
    () => import("../components/Subject/EditView")
  );

  const UsersList = React.lazy(() => import("../components/Users/ListView"));
  const AddUser = React.lazy(() => import("../components/Users/AddView"));
  const EditUser = React.lazy(() => import("../components/Users/Edit/TabView"));

  // const TopicList = React.lazy(() => import("../components/Topics/List"));
  // const AddTopic = React.lazy(() => import("../components/Topics/AddView"));
  // const EditTopic = React.lazy(() => import("../components/Topics/EditView"));

  // const TopicList = React.lazy(() => import("../components/Topics/List"));
  const SubscriptionList = React.lazy(
    () => import("../components/Subscription/TabView")
  );
  const AddSubscriptionForStudent = React.lazy(
    () => import("../components/Subscription/Student/AddView")
  );
  const EditSubscriptionForStudent = React.lazy(
    () => import("../components/Subscription/Student/EditView")
  );

  const AddSubscriptionForSchool = React.lazy(
    () => import("../components/Subscription/School/AddView")
  );
  const EditSubscriptionForSchool = React.lazy(
    () => import("../components/Subscription/School/EditView")
  );

  // const EditTopic = React.lazy(() => import("../components/Topics/EditView"));

  const SubjectTopicsList = React.lazy(
    () => import("../components/SubjectTopics/List")
  );
  const AddSubjectTopic = React.lazy(
    () => import("../components/SubjectTopics/AddView")
  );
  const EditSubjectTopic = React.lazy(
    () => import("../components/SubjectTopics/EditView")
  );

  const QuizList = React.lazy(() => import("../components/Games/Quiz/List"));
  const AddQuiz = React.lazy(() => import("../components/Games/Quiz/AddView"));
  const EditQuiz = React.lazy(
    () => import("../components/Games/Quiz/EditView")
  );
  // const QuizOptionList = React.lazy(() => import("../components/Games/Quiz/Options/TabView"));
  const QuizOptionList = React.lazy(
    () => import("../components/Games/Quiz/OptionsView/TabView")
  );
  const QuizOptionImport = React.lazy(
    () => import("../components/Games/Quiz/OptionsView/Import")
  );

  const ComprehensiveList = React.lazy(
    () => import("../components/Games/Comprehensive/List")
  );
  const AddComprehensive = React.lazy(
    () => import("../components/Games/Comprehensive/AddView")
  );
  const EditComprehensive = React.lazy(
    () => import("../components/Games/Comprehensive/EditView")
  );

  const ComprehensiveOptionList = React.lazy(
    () => import("../components/Games/Comprehensive/OptionsView/TabView")
  );

  const InteractiveList = React.lazy(
    () => import("../components/Games/Interactive Diagram/List")
  );
  const AddInteractive = React.lazy(
    () => import("../components/Games/Interactive Diagram/AddView")
  );
  const EditInteractive = React.lazy(
    () => import("../components/Games/Interactive Diagram/EditView")
  );
  const InteractiveOptionList = React.lazy(
    () => import("../components/Games/Interactive Diagram/Options/ListView")
  );

  const PhotoGamesList = React.lazy(
    () => import("../components/Games/Photo Game/List")
  );
  const AddPhotoGame = React.lazy(
    () => import("../components/Games/Photo Game/AddView")
  );
  const EditPhotoGame = React.lazy(
    () => import("../components/Games/Photo Game/EditView")
  );
  const PhotoGameOptionList = React.lazy(
    () => import("../components/Games/Photo Game/Options/ListView")
  );

  const MemoryGamesList = React.lazy(
    () => import("../components/Games/Memory/List")
  );
  const AddMemoryGame = React.lazy(
    () => import("../components/Games/Memory/AddView")
  );
  const EditMemoryGame = React.lazy(
    () => import("../components/Games/Memory/EditView")
  );

  const ManageMemoryGameTypes = React.lazy(
    () => import("../components/Games/Memory/ManageTypes/List")
  );

  const MemoryGameOptionList = React.lazy(
    () => import("../components/Games/Memory/OptionsView/TabView")
  );

  const SlideList = React.lazy(
    () => import("../components/Games/Slide Puzzle/List")
  );
  const AddSlide = React.lazy(
    () => import("../components/Games/Slide Puzzle/AddView")
  );
  const EditSlide = React.lazy(
    () => import("../components/Games/Slide Puzzle/EditView")
  );

  const SlideOptionList = React.lazy(
    () => import("../components/Games/Slide Puzzle/OptionsView/TabView")
  );

  const JigsawList = React.lazy(
    () => import("../components/Games/Jigsaw Puzzle/List")
  );
  const AddJigsaw = React.lazy(
    () => import("../components/Games/Jigsaw Puzzle/AddView")
  );
  const EditJigsaw = React.lazy(
    () => import("../components/Games/Jigsaw Puzzle/EditView")
  );

  const JigsawOptionList = React.lazy(
    () => import("../components/Games/Jigsaw Puzzle/OptionsView/TabView")
  );

  const VocabularyList = React.lazy(
    () => import("../components/Games/Vocabulary/List")
  );
  const AddVocabulary = React.lazy(
    () => import("../components/Games/Vocabulary/AddView")
  );
  const EditVocabulary = React.lazy(
    () => import("../components/Games/Vocabulary/EditView")
  );
  // const VocabularyOptionList = React.lazy(() => import("../components/Games/Vocabulary/Options/TabView"));
  const VocabularyOptionList = React.lazy(
    () => import("../components/Games/Vocabulary/OptionsView/TabView")
  );
  const VocabularyOptionImport = React.lazy(
    () => import("../components/Games/Vocabulary/OptionsView/Import")
  );

  const FlowChartList = React.lazy(
    () => import("../components/Games/Flow Charts/List")
  );
  const AddFlowChart = React.lazy(
    () => import("../components/Games/Flow Charts/AddView")
  );
  const EditFlowChart = React.lazy(
    () => import("../components/Games/Flow Charts/EditView")
  );
  // const FlowChartOptionList = React.lazy(() => import("../components/Games/Flow Charts/Options/TabView"));
  const FlowChartOptionList = React.lazy(
    () => import("../components/Games/Flow Charts/OptionsView/TabView")
  );

  const BrainTeaserList = React.lazy(
    () => import("../components/Games/Brain Teaser/List")
  );
  const AddBrainTeaser = React.lazy(
    () => import("../components/Games/Brain Teaser/AddView")
  );
  const EditBrainTeaser = React.lazy(
    () => import("../components/Games/Brain Teaser/EditView")
  );

  const SuccessSent = React.lazy(() => import("../components/Login/Success"));

  const AccessDenied = React.lazy(
    () => import("../components/Common/AccessDenied/AccessDenied")
  );

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/otp"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Otp />{" "}
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/success"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <SuccessSent />{" "}
              </Suspense>
            }
          />
          <Route
            path="/access-denied"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <AccessDenied />{" "}
              </Suspense>
            }
          />
          <Route
            path="/change-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ChangePassword />{" "}
              </Suspense>
            }
          />

          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/schools"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SchoolsList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-school"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSchool />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-school"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSchool />{" "}
                </Suspense>
              }
            />
            <Route
              path="/schools/import"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SchoolImport />{" "}
                </Suspense>
              }
            />
            <Route
              path="/school/:school_uuid/teachers"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <TeachersList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/school/:school_uuid/add-teacher"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddTeacher />{" "}
                </Suspense>
              }
            />
            <Route
              path="/school/:school_uuid/edit-teacher"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditTeacher />{" "}
                </Suspense>
              }
            />
            <Route
              path="/school/:school_uuid/teacher/:teacher_uuid/classes"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <TeacherClassesList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/classes"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ClassesList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-class"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddClass />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-class"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditClass />{" "}
                </Suspense>
              }
            />
            <Route
              path="/standards"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <StandardList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-standard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddStandard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-standard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditStandard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subjects"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SubjectList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-subject"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubject />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-subject"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSubject />{" "}
                </Suspense>
              }
            />
            <Route
              path="/users"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <UsersList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-user"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddUser />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-user"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditUser />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SubscriptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscription/student/add"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscriptionForStudent />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscription/student/edit"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSubscriptionForStudent />{" "}
                </Suspense>
              }
            />

            <Route
              path="/subscription/school/add"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscriptionForSchool />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscription/school/edit"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSubscriptionForSchool />{" "}
                </Suspense>
              }
            />
            {/* <Route
              path="/topics"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <TopicList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-topic"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddTopic />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-topic"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditTopic />{" "}
                </Suspense>
              }
            /> */}
            <Route
              path="/subject/:uuid/topics"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SubjectTopicsList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subject/:uuid/add-topic"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubjectTopic />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subject/:uuid/edit-topic"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSubjectTopic />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/quiz"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <QuizList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-quiz"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddQuiz />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-quiz"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditQuiz />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/quiz/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <QuizOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/quiz/:uuid/import-options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <QuizOptionImport />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/comprehensive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ComprehensiveList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-comprehensive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddComprehensive />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-comprehensive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditComprehensive />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/comprehensive/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ComprehensiveOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/interactive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <InteractiveList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-interactive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddInteractive />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-interactive"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditInteractive />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/interactive/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <InteractiveOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/photo-games"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <PhotoGamesList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-photo-game"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddPhotoGame />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-photo-game"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditPhotoGame />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/photo-game/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <PhotoGameOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/memory"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <MemoryGamesList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-memory"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddMemoryGame />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-memory"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditMemoryGame />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/memory/types"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageMemoryGameTypes />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/memory/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <MemoryGameOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/brain-teaser"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <BrainTeaserList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-brain-teaser"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddBrainTeaser />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-brain-teaser"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditBrainTeaser />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/slide"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SlideList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-slide"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSlide />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-slide"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditSlide />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/slide/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SlideOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/jigsaw"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <JigsawList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-jigsaw"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddJigsaw />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-jigsaw"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditJigsaw />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/jigsaw/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <JigsawOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/vocabulary"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <VocabularyList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-vocabulary"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddVocabulary />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-vocabulary"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditVocabulary />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/vocabulary/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <VocabularyOptionList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/vocabulary/:uuid/import-options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <VocabularyOptionImport />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/flow-charts"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <FlowChartList />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/add-flow-chart"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddFlowChart />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/edit-flow-chart"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EditFlowChart />{" "}
                </Suspense>
              }
            />
            <Route
              path="/:type/flow-chart/:uuid/options"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <FlowChartOptionList />{" "}
                </Suspense>
              }
            />
          </Route>
          <Route
            index
            element={
              <Suspense fallback={<></>}>
                {" "}
                <DefaultPage />{" "}
              </Suspense>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
